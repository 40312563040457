.settings-container {
  width: 100%;
  min-height: 90vh;
  button {
    border: none;
    background: none;
    display: block;
  }
  .tabs {
    display: flex;
    align-items: center;
    gap: 24px;
    border-bottom: 1px solid #e1d8f2;
    margin-bottom: 32px;
    padding: 0 24px;
    .tab {
      width: 72px;
      height: 32px;
      font-size: 14px;
      font-weight: 600;
      color: #928e94;
      transition: all 0.2s ease-in-out;
      &.active {
        color: #5341c5;
        border-bottom: 2px solid #5341c5;
        transition: all 0.3s ease-in-out;
      }
    }
  }
  .settings-form {
    width: 96%;
    padding: 3%;
    padding-top: 0;
    gap: 32px;
    text-align: center;
    @media (min-width: 1200px) {
      width: 75%;
      padding: 4%;
      padding-top: 12px;
    }
    h6 {
      font-size: 22px;
      font-weight: normal;
      margin-bottom: 32px;
      text-align: left;
    }
    .form-content {
      display: flex;
      align-items: center;
      gap: 24px;
      padding-bottom: 32px;
      border-bottom: 1px solid #c6d1e0;
      &.no-border {
        border-bottom: 0px solid transparent;
      }
    }
    .fields {
      width: 70%;
      margin-top: 32px;
      .form-group {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
        .modal-input {
          border: none;
          padding: 10px;
          padding-left: 0;
          border-bottom: 1px solid #adb8c7;
          width: 100%;
          color: #111111;
          outline: none;
          transition: all 0.2s ease-in;
          font-size: 16px;
          &::placeholder {
            color: #505963;
          }
          &:active,
          &:after,
          &:focus,
          &:visited {
            outline: none;
            border-bottom: 1px solid #5341c5;
          }
        }
      }
    }
    .image-upload {
      width: 30%;
      img {
        width: 140px;
        height: 140px;
      }
      button {
        margin: 12px auto;
        color: #5341c5;
        font-weight: 600;
      }
    }
    .submit-changes {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 32px;
      width: 94%;
      margin-top: 32px;
      button {
        background: none;
        border: none;
        padding: 10px 16px;
        border-radius: 3px;
        font-weight: 600;
        &.cancel {
          background-color: #f2f2f2;
          color: #555063;
        }
        &.danger {
          background-color: #f04242;
          color: #ffffff;
        }
        &.primary {
          color: #ffffff;
          background-color: #5341c5;
        }
        &.plain {
          color: #555063;
          background-color: #ffffff;
        }
      }
    }
  }
  .cards {
    width: 96%;
    padding: 3%;
    padding-top: 0;
    button {
      background-color: #5341c4;
      border-radius: 3px;
      color: #fff;
      padding: 10px 20px;
    }
    .cards-content {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 24px;
      .debit-card {
        border-radius: 14px;
        background-color: #6fcf97;
        color: #222222;
        padding: 16px 30px;
        width: 320px;
        height: 180px;
        position: relative;
        transition: all 0.8s ease-in-out;
        &:hover {
          background-color: #73e2a1;
        }
        &-number {
          position: absolute;
          top: 30%;
          font-size: 16px;
          font-weight: 600;
        }
        &-default {
          position: absolute;
          top: 12%;
          right: 0;
          background-color: #f2f2f2;
          color: #5341c4;
          padding: 8px 20px;
          border-top-left-radius: 20px;
          border-bottom-left-radius: 20px;
        }
        &-base {
          position: absolute;
          bottom: 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          img {
            width: 44px;
            position: absolute;
            right: 54px;
          }
          .expiry {
            span {
              display: block;
              &.exp-date {
                font-size: 16px;
                font-weight: 500;
              }
            }
          }
        }
      }
    }
  }

  .users {
    width: 96%;
    padding: 3%;
    padding-top: 0;
    h6 {
      font-size: 22px;
      font-weight: normal;
      margin-bottom: 32px;
      text-align: left;
    }
    button {
      background-color: #5341c4;
      border-radius: 3px;
      color: #fff;
      padding: 10px 20px;
    }
  }
}
.settings-table {
  width: 100%;
  @media (min-width: 1200px) {
    width: 90%;
    max-width: 1000px;
  }
  table {
    font-size: 14px;
    font-weight: 500;
    thead {
      color: #000000;
      th {
        padding: 16px 0;
      }
    }
    tbody {
      color: #484848;
      tr {
        border-bottom: 1px solid #f3f3f3;
        &:hover {
          background-color: #f8f7ff;
          cursor: pointer;
        }
      }
      td {
        padding: 22px 0;
        &.row-action {
          display: flex;
          align-items: center;
          gap: 12px;
          button {
            font-weight: 600;
            font-size: 14px;
            padding: 6px 12px;
            &.plain {
              color: #555063;
              background-color: transparent;
            }
            &.remove {
              background-color: #c62828;
              color: #fff;
            }
          }
        }
      }
    }
  }

  .side-panel {
    &-body {
      .tabs {
        display: flex;
        align-items: center;
        gap: 24px;
        border-bottom: 1px solid #e1d8f2;
        margin-bottom: 32px;
        .tab {
          width: 72px;
          height: 32px;
          font-size: 14px;
          font-weight: 600;
          color: #928e94;
          transition: all 0.5s ease-in-out;
          &.active {
            color: #5341c5;
            border-bottom: 2px solid #5341c5;
            transition: all 0.3s ease-in-out;
          }
        }
      }
      .user-info {
        padding: 0 12px;
        width: 98%;
        margin-bottom: 32px;
        border-bottom: 1px solid #e1d8f2;
        .code {
          font-size: 22px;
          font-weight: 600;
          color: #111111;
        }
      }
      .others {
        padding: 0 12px;
        margin-bottom: 32px;
        .other {
          display: block;
          margin-bottom: 16px;
          span {
            color: #6d686f;
            font-size: 15px;
            font-weight: 500;
            display: block;
            &.lbl {
              font-size: 14px;
              font-weight: 450;
              color: #6d686f;
              line-height: 20px;
            }
            &.value {
              color: #555063;
              font-size: 20px;
              &.expiry {
                color: #f04242;
              }
            }
          }
        }
      }
      .actions {
        padding: 0 12px;
        display: flex;
        align-items: center;
        margin-bottom: 32px;
        gap: 24px;
        button {
          padding: 8px 16px;
          border-radius: 3px;
          font-weight: 600;
          background-color: #5341c5;
          border: 2px solid #5341c4;
          color: #ffffff;
          transition: all 0.3s ease-in-out;
          &:hover {
            background-color: #6551e8;
            cursor: pointer;
          }
          &.a {
          }
          &.b {
            color: #5341c4;
            background-color: transparent;
            border: 2px solid #5341c4;
          }
        }
      }
      button.delete {
        padding: 8px 16px;
        color: #f04242;
        font-weight: 600;
        font-size: 14px;
      }
      .visitorrs {
        .pass-details {
          margin-bottom: 16px;
          padding-bottom: 16px;
          border-bottom: 1.5px solid #f2f2f2;
          width: 98%;
          @media (min-width: 1200px) {
            width: 90%;
          }
          img {
            &.item-icon {
              width: 60px;
              height: 60px;
              margin-right: 10px;
            }
            &.data-icon {
              height: 16px;
            }
          }
          .pass-type {
            color: #555063;
            font-weight: 600;
            font-size: 14px;
            line-height: 16px;
          }
          .user {
            color: #6d686f;
            font-size: 16px;
            line-height: 32px;
            font-weight: 500;
          }
          .address {
            margin-bottom: 4px;
            span {
              color: #928e94;
              line-height: 28px;
              font-size: 14px;
              font-weight: 500;
              line-height: 18px;
            }
            img {
              margin-right: 4px;
            }
          }
          .time-access {
            .time {
              margin-right: 8px;
            }
            img.data-icon {
              margin-right: 4px;
            }
            .status {
              padding: 4px 10px;
              color: #505963;
              font-size: 10px;
              font-weight: 600;
              border-radius: 2px;
              text-transform: uppercase;
              line-height: 12px;
              &.granted {
                background-color: #a9dfbf;
              }
              &.declined {
                background-color: #ffe2e1;
              }
            }
          }
        }
      }
    }
  }

  @keyframes slideIn {
    0% {
      transform: translateX(220px);
    }
    100% {
      transform: translateX(0);
    }
  }
}
